type Query = {
  [key: string]: string
}

const serialize = (obj: Query) => {
  const str = []
  for (const p in obj) {
    if (obj[p] !== undefined && obj[p] !== null) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    }
  }
  return str.join('&')
}

const updateQuery = (query: Query, key: string, value: string): Query => {
  // Reset paging on any filter change
  if ('page' in query) {
    delete query.page
  }
  // Add or replace object being changed
  if (value === '0') {
    delete query[key]
  } else {
    query[key] = value
  }
  return query
}

const resetUrl = (path: string, appName: string): string => (
  path.indexOf(`/${appName}/`) === 0 ? `/${appName}` : path.split(`/${appName}/`)[0] + `/${appName}`
)

type FilterState = {
  category: string | null
  material: string | null
  service_area: string | null
}

const buildUrl = (
  filterState: FilterState,
  key: keyof FilterState,
  value: string,
  path: string,
  appName: string
): string => {
  let newFilterState = { ...filterState }
  if (key === 'material' && value !== '0') {
    const [category, material] = value.split('__')
    newFilterState = { ...newFilterState, category, material }
  } else if (key === 'category' && value !== filterState.category && filterState.material) {
    // Changing category must set material to null
    newFilterState = {
      ...newFilterState,
      category: value,
      material: null
    }
  } else {
    newFilterState = { ...newFilterState, [key]: value }
  }

  if (newFilterState[key] === '0') {
    newFilterState[key] = null
  }

  let rest = ''
  if (newFilterState.category) {
    rest += `${newFilterState.category}/`
  }
  if (newFilterState.material) {
    rest += `${newFilterState.material}/`
  }
  if (newFilterState.service_area) {
    rest += `${newFilterState.service_area}/`
  }

  const baseUrl = resetUrl(path, appName)
  return `${baseUrl}/${rest}`
}

type WindowWithUpdateQuery = Window & {
  utils: {
    updateQuery: (query: Query, key: string, value: string) => Query
    resetUrl: (path: string, appName: string) => string
    buildUrl: (
      filterState: FilterState,
      key: string,
      value: string,
      path: string,
      appName: string
    ) => string
    serialize: (obj: Query) => string
  }
}

(window as unknown as WindowWithUpdateQuery).utils = {
  updateQuery,
  resetUrl,
  buildUrl,
  serialize
}
